import './App.css';
import {useEffect, useState} from "react";

function App() {
    const [online, setOnline] = useState([]);

    useEffect(() => {
        fetch("https://api.yiffed.me/botcount/")
            .then(response => {
                return response.json()
            })
            .then(data => {
                setOnline(data)
            })
            .catch(err =>
                console.log(err))
    }, []);

    return (
        <div className="App">
            <header className="Main-header">
                <h1 className="display-1">Nanofaux.</h1>
            </header>
            <div className="Body-main">
                <p className="text-primary-emphasis">Bots online: {online.count}</p>
                <p className="text-primary-emphasis"><code><a className="link-unstyled"
                                                              href="https://trello.com/b/FLswdJvH/bot-queue">Queue</a></code>
                </p>
            </div>
            <div className="Socials container text-center">
                <div className="row align-items-center">
                    <div className="col">
                        <p className="text-secondary">Discord: <code><a className="link-unstyled"
                                                                        href="https://yiffed.me/">yiffyfox</a></code>
                        </p>
                    </div>
                    <div className="col">
                        <p className="text-secondary">GitHub:<br/><code><a className="link-unstyled"
                                                                           href="https://github.com/Nanofaux">Nanofaux</a></code>
                        </p>
                    </div>
                    <div className="col">
                        <p className="text-secondary">Twitter: <code><a className="link-unstyled"
                                                                        href="https://x.com/Nanofaux">@Nanofaux</a></code>
                        </p>
                    </div>
                </div>
            </div>
        </div>
    );
}

console.log(1)
export default App;
